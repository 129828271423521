<template>
  <el-form-item
    :label="formItem.name + '：'"
    :key="`radio-${formItem.identifier}`"
    :prop="propVal"
  >
    <el-radio-group v-model="formVal[formItem.identifier]">
      <el-radio v-for="item in radioRange" :key="item[0]" :label="item[0]">{{
        item[1]
      }}</el-radio>
    </el-radio-group>
  </el-form-item>
</template>

<script>
export default {
  name: "radio",
  props: {
    formItem: {
      type: Object,
    },
    formVal: {},
    parentId: {},
    formRules: {},
  },
  computed: {
    propVal() {
      return this.parentId + "." + this.formItem.identifier;
    },
    radioRange() {
      return Object.entries(this.formItem.uiType.specs);
    },
  },
};
</script>
<style lang="scss" scoped>
::v-deep {
  .el-form-item__error {
    top: 80%;
    // padding-top: 0;
  }
}
</style>
